<script>
    import Toast from '@landing/components/Toast.svelte'
    import { toasts } from '@landing/stores/toasts'

    function onCloseToast (id) {
        toasts.remove(id)
    }
</script>

<div id="statusbar" role="status">
    {#each [...$toasts.entries()] as [id, toast] (id) }
        <Toast type={toast.type} on:click={onCloseToast(id)}>
            {toast.message}
        </Toast>
    {/each}
</div>

<style lang="scss">
    @import "../style/variables";

    #statusbar {
        background-color: $dark-color;
    }
</style>
